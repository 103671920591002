<template>
  <div>
    <h2>Wieso steht bei {{ tarif.tier_title }} ein "Circa" Preis?</h2>
    Bislang wurden uns von {{ tarif.un_name }} noch keine vollständigen
    Beitragstabellen zur Verfügung gestellt. Wir haben daher die Beiträge
    manuell berechnet. Diese können aber von den richtigen Beiträgen etwas
    abweichen.
  </div>
</template>

<script>
  export default {
    name: "index-preis-est",
    props: ["tarif"],
    components: {},
    computed: {},
  };
</script>
