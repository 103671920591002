<template>
  <div>
    <span v-if="erstattunggesamt == 0">
      <h3>
        Für {{ tarif.tier_title }} wurde noch keine Berechnung hinterlegt.
      </h3>
      Wir werden diese in den nächsten Tagen hinterlegen.
    </span>
    <span v-if="erstattunggesamt > 0 && showBer">
      <h3>
        {{ tarif.tier_title }} im Test | so wurde der Eisbaum Realwert ermittelt
      </h3>

      <v-row>
        <v-col cols="12" md="12"
          >Die {{ tarif.tier_title }}
          <span v-if="tierart == 1">Hunde-Krankenversicherung</span>
          <span v-if="tierart == 2">Katzen-Krankenversicherung</span>
          hat von
          <b class="red--text">{{ rechnungengesamt }},- €</b> angefallener
          Tierarztrechnungen (im Laufe von 5 Versicherungsjahren)
          <b class="green--text">{{ realwert.real_erstattung_gesamt }},- € </b>
          erstattet. Dies enstpricht einer Erstattung von
          <b class="green--text">{{ tarif.tier_wert }} %</b></v-col
        >
      </v-row>
      <br />

      <v-simple-table>
        <tbody>
          <tr>
            <td><h3>Tierarzt Rechnungen im 1. Jahr</h3></td>
          </tr>
          <tr
            style="vertical-align:top;"
            v-for="rechnung in jahr1.rechnungen"
            :key="rechnung.nr"
          >
            <td>
              <b>{{ rechnung.nr }}. {{ rechnung.name }}</b>
              <ul>
                <li
                  v-for="position in rechnung.positionen"
                  :key="position.name"
                >
                  {{ position.name }}: <b>{{ position.kosten }},- €</b>
                </li>
              </ul>
            </td>
          </tr>
          <tr style="background-color:#f2f2f2">
            <td>
              Gesamt Tierarztkosten im 1. Jahr <b>{{ jahr1.kostengesamt }},-€</b
              ><br />
              Gesamt Erstattung durch {{ tarif.tier_title }} im 1. Jahr
              <b class="green--text">{{ realwert.real_erstattung_j1 }},-€</b>
            </td>
          </tr>
          <tr>
            <td><h3 class="mt-8">Tierarzt Rechnungen im 2. Jahr</h3></td>
          </tr>
          <tr
            style="vertical-align:top;"
            v-for="rechnung in jahr2.rechnungen"
            :key="rechnung.nr"
          >
            <td>
              <b>{{ rechnung.nr }}. {{ rechnung.name }}</b>
              <ul>
                <li
                  v-for="position in rechnung.positionen"
                  :key="position.name"
                >
                  {{ position.name }}: <b>{{ position.kosten }},- €</b>
                </li>
              </ul>
            </td>
          </tr>

          <tr style="background-color:#f2f2f2">
            <td>
              Gesamt Tierarztkosten im 2. Jahr <b>{{ jahr2.kostengesamt }},-€</b
              ><br />
              Gesamt Erstattung durch {{ tarif.tier_title }} im 2. Jahr
              <b class="green--text">{{ realwert.real_erstattung_j2 }},-€</b>
            </td>
          </tr>

          <tr>
            <td><h3 class="mt-8">Tierarzt Rechnungen im 3. Jahr</h3></td>
          </tr>
          <tr
            style="vertical-align:top;"
            v-for="rechnung in jahr3.rechnungen"
            :key="rechnung.nr"
          >
            <td>
              <b>{{ rechnung.nr }}. {{ rechnung.name }}</b>
              <ul>
                <li
                  v-for="position in rechnung.positionen"
                  :key="position.name"
                >
                  {{ position.name }}: <b>{{ position.kosten }},- €</b>
                </li>
              </ul>
            </td>
          </tr>

          <tr style="background-color:#f2f2f2">
            <td>
              Gesamt Tierarztkosten im 3. Jahr <b>{{ jahr3.kostengesamt }},-€</b
              ><br />
              Gesamt Erstattung durch {{ tarif.tier_title }} im 3. Jahr
              <b class="green--text">{{ realwert.real_erstattung_j3 }},-€</b>
            </td>
          </tr>

          <tr>
            <td><h3 class="mt-8">Tierarzt Rechnungen im 4. Jahr</h3></td>
          </tr>
          <tr
            style="vertical-align:top;"
            v-for="rechnung in jahr4.rechnungen"
            :key="rechnung.nr"
          >
            <td>
              <b>{{ rechnung.nr }}. {{ rechnung.name }}</b>
              <ul>
                <li
                  v-for="position in rechnung.positionen"
                  :key="position.name"
                >
                  {{ position.name }}: <b>{{ position.kosten }},- €</b>
                </li>
              </ul>
            </td>
          </tr>

          <tr style="background-color:#f2f2f2">
            <td>
              Gesamt Tierarztkosten im 4. Jahr <b>{{ jahr4.kostengesamt }},-€</b
              ><br />
              Gesamt Erstattung durch {{ tarif.tier_title }} im 4. Jahr
              <b class="green--text">{{ realwert.real_erstattung_j4 }},-€</b>
            </td>
          </tr>

          <tr>
            <td><h3 class="mt-8">Tierarzt Rechnungen im 5. Jahr</h3></td>
          </tr>
          <tr
            style="vertical-align:top;"
            v-for="rechnung in jahr5.rechnungen"
            :key="rechnung.nr"
          >
            <td>
              <b>{{ rechnung.nr }}. {{ rechnung.name }}</b>
              <ul>
                <li
                  v-for="position in rechnung.positionen"
                  :key="position.name"
                >
                  {{ position.name }}: <b>{{ position.kosten }},- €</b>
                </li>
              </ul>
            </td>
          </tr>

          <tr style="background-color:#f2f2f2">
            <td>
              Gesamt Tierarztkosten im 5. Jahr <b>{{ jahr5.kostengesamt }},-€</b
              ><br />
              Gesamt Erstattung durch {{ tarif.tier_title }} im 5. Jahr
              <b class="green--text">{{ realwert.real_erstattung_j5 }},-€</b>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </span>
  </div>
</template>

<script>
  import fieldMixin from "../mixins/fieldMixin";
  export default {
    name: "index-rechenweg",
    mixins: [fieldMixin],
    props: ["tarif", "tierart"],
    components: {},
    data: function() {
      return {
        showBer: false,
        jahr1: false,
        jahr2: false,
        jahr3: false,
        jahr4: false,
        jahr5: false,
        erstattungen: 0,
        rechnungengesamt: 0,
        erstattunggesamt: 0,
        select: {
          type: "einzel",
          tierart: this.tierart,
          id: this.tarif.tier_id,
          number: "one",
        },
        selectreal: {
          type: "einzel",
          tierart: this.tierart,
          id: this.tarif.tier_id,
          number: "one",
        },
      };
    },
    created() {
      this.getTarifBenchmark();
      this.getRealwert();
    },

    methods: {
      calcBenchmarkDetails() {
        this.$log.debug("benchstart");
        let anzahl = 0;
        this.erstattunggesamt = 0;
        this.benchmark.forEach((item) => {
          if (item.bench_value > 0) {
            this.erstattunggesamt =
              this.erstattunggesamt + parseInt(item.bench_value);
            anzahl = anzahl + 1;
          }
        });

        this.erstattungen = anzahl;
      },
      getRealwert() {
        this.$log.debug("bench");
        this.$store
          .dispatch("getRealwert", this.selectreal)
          .then((resp) => {
            if (resp.data) {
              this.realwert = resp.data.realwert;
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      getTarifBenchmark() {
        this.$log.debug("get bench");
        this.$store
          .dispatch("getTarifBenchmark", this.select)
          .then((resp) => {
            if (resp.data) {
              this.showBer = true;
              this.jahr1 = resp.data.jahr1;
              this.jahr2 = resp.data.jahr2;
              this.jahr3 = resp.data.jahr3;
              this.jahr4 = resp.data.jahr4;
              this.jahr5 = resp.data.jahr5;
              this.erstattunggesamt = resp.data.erstattunggesamt;
              this.rechnungengesamt = resp.data.rechnungengesamt;
              // this.calcBenchmarkDetails();
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
    },
    computed: {},
  };
</script>
