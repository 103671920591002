<template>
  <div>
    <v-card class="pa-4">
      <index-preis-est v-if="type == 'preis_est'" :tarif="item" />
      <index-rechenweg :tierart="1" v-if="type == 'rechenweg'" :tarif="item" />
    </v-card>
  </div>
</template>

<script>
  import IndexRechenweg from "./../../hund/IndexRechenweg";
  import IndexPreisEst from "./../../hund/IndexPreisEst";
  export default {
    name: "spezial-pop",
    props: ["item", "type"],
    components: {
      IndexRechenweg,
      IndexPreisEst,
    },
    computed: {},
  };
</script>
